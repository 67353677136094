import React, { useRef, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import { Masthead } from "../components/layout/masthead"
import Clients from "../components/layout/clients"
import Process from "../components/layout/process"
import Services from "../components/layout/services"
import Contact from "../components/layout/contact"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Helmet from 'react-helmet';
import { Layout } from "../components/global/layout"

const AboutPage = ({ data, location }) => {
    gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);
    const vw = (coef) => window.innerWidth * (coef / 100);
    useEffect(() => {
        let mm = gsap.matchMedia();

        mm.add("(min-width: 768px)", () => {
            gsap.utils.toArray(".js-trigger").forEach(function (section, i) {
                ScrollTrigger.create({
                    trigger: section,
                    start: () => `top ${vw(5)}`,
                    end: () => `bottom ${vw(5)}`,
                    //markers: true,
                    scrub: true,
                    toggleClass: { targets: ".js-about-title", className: `is-section-${i + 1}` },
                });
            });
        });

        mm.add("(max-width: 767px)", () => {
            gsap.utils.toArray(".js-trigger").forEach(function (section, i) {
                ScrollTrigger.create({
                    trigger: section,
                    start: () => `top ${vw(10)}`,
                    end: () => `bottom ${vw(10)}`,
                    //markers: true,
                    scrub: true,
                    toggleClass: { targets: ".js-about-title", className: `is-section-${i + 1}` },
                });
            });
        });
    }, []);

    if (!data)
        return null

    const document = data.prismicAbout.data

    return (
        <Layout
            pageTitle={document.title.text}
            metaTitle={document.meta_title.text}
            metaDescription={document.meta_description.text}
            ogType="website"
            shareImage={document.share_image.url}
            canonicalUrl={location.pathname}>
            <Helmet
                bodyAttributes={{
                    class: 'is-about'
                }}
            />
            <div ref={ref}>
                <div className="c-navbar__about">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="c-navbar__about-container">
                                <h1 className="c-navbar__about-title js-about-title">
                                    <span>
                                        About
                                    </span>
                                    <span>
                                        Clients
                                    </span>
                                    <span>
                                        Process
                                    </span>
                                    <span>
                                        Services
                                    </span>
                                </h1>
                                <Link className="c-navbar__about-link" to="/contact"> Contact</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Masthead title={document.title.text}
                    summary={document.summary.richText}
                    cssClass="c-masthead--about" />
                <Clients data={data.prismicHome.data.clients} cssClass="c-clients--about js-trigger" />
                <Process title={data.prismicAbout.data.process_title.text} items={data.prismicAbout.data.process_items} cssClass="c-services--padding-bottom js-trigger" />
                <Services title={data.prismicAbout.data.services_title.text} items={data.prismicAbout.data.services_items} cssClass="js-trigger" />
                <Contact cssClass="c-contact--about" />
            </div>
        </Layout>
    )
}

export const query = graphql`
  query AboutQuery {
    prismicAbout {
      _previewable
      id
      lang
      type
      url
      data {
        title {
          text
        }
        summary {
          richText
        }
        process_items {
          process_item_title {
            text
          }
          process_item_content {
            richText
          }
        }
        process_title {
          text
        }
        services_items {
          services_item_title {
            text
          }
          services_item_content {
            richText
          }
        }
        services_title {
          text
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        share_image {
          url
        }
      }
    }
    prismicHome {
        _previewable
        id
        lang
        type
        url
        data {
          clients {
            client_image {
              url
              dimensions {
                height
                width
              }
            }
            client_name {
              text
            }
            client_video {
              url
            }
            client_video_orientation
          }
        }
      }
  }
`

export default withPrismicPreview(AboutPage, repositoryConfigs)

