import React from 'react'

export const Contact = ({ cssClass }) => {
    return (
        <div className={`c-contact js-text-visibility ${cssClass ? cssClass : ''}`}>
            <div className="container-fluid">
                <div className="row">
                    <div className="c-contact__content">
                        To discuss a project<br />
                        or for more information,<br />
                        contact us:<br /><br />
                        <a href="mailto:hello@twocreate.co.uk">hello@twocreate.co.uk <svg fill="none" viewBox="0 0 17 22"><path d="m8.298 21.2 8.1-7.5-1.2-1.32-6.03 5.61V.47h-1.74v17.52l-6-5.61-1.23 1.32 8.1 7.5Z" fill="#0C0C0C" /></svg></a><br />
                        <a href="tel:+44(0)2038758133">+44 (0)20 3875 8133</a>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Contact