import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { RichText } from 'prismic-reactjs'
import CustomLink from '../../utils/prismic-content-link'

export const Process = ({ title, items, cssClass }) => {
    return (
        <div className={`c-services js-text-visibility ${cssClass ? cssClass : ''}`}>
            <div className="container-fluid">
                <div className="row">
                    <div className="c-services__header">
                        <h2 className="c-services__header-title">Process</h2>
                    </div>
                </div>
                <div className="row">
                    <p className="c-services__summary">{title}</p>
                    <Accordion className="c-services__accordion" allowZeroExpanded>
                        {items.map((item, i) => {
                            if (item) {
                                return (
                                    <AccordionItem className="c-services__accordion-item" activeClassName="c-services__accordion-item is-active" key={i}>
                                        <AccordionItemHeading className="c-services__accordion-item-heading">
                                            <AccordionItemButton className="c-services__accordion-item-btn">
                                                <div>{item.process_item_title.text}</div>
                                                <div>
                                                    <svg fill="none" viewBox="0 0 17 22"><path d="m8.298 21.2 8.1-7.5-1.2-1.32-6.03 5.61V.47h-1.74v17.52l-6-5.61-1.23 1.32 8.1 7.5Z" fill="#0C0C0C" /></svg>
                                                </div>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel className="c-services__accordion-item-panel cms-content">
                                            <RichText render={item.process_item_content.richText} serializeHyperlink={CustomLink} />
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                )
                            }
                            else {
                                return null
                            }
                        })}
                    </Accordion>
                </div>
            </div>
        </div>
    )
}


export default Process
