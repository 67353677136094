import React from 'react'
import { Link } from 'gatsby'
import AvoidOrphan from '../../utils/text-avoid-orphan'

const ProjectListIndexItem = ({ article }) => {
    if (article) {
        return (
            <div className="c-project-listing__index-item">
                <Link className="c-project-listing__index-item-link" to={article.url}>
                    <div className="c-project-listing__index-item-media-container">
                        <div className="c-project-listing__index-item-media-wrapper">
                            {article.data.index_image.url ? 
                            <picture>
                                <source srcSet={`${article.data.index_image.url}&w=1200&fit=max`} media="(min-width: 1500px)" />
                                <source srcSet={`${article.data.index_image.url}&w=800&fit=max`} media="(min-width: 1200px)" />
                                <source srcSet={`${article.data.index_image.url}&w=500&fit=max`} media="(min-width: 768px)" />
                                <source srcSet={`${article.data.index_image.url}&w=1000&fit=max`} media="(min-width: 500px)" />
                                <img src={`${article.data.index_image.url}&w=700&fit=max`} alt="" className="c-project-listing__index-item-media" loading="lazy" width={article.data.index_image.dimensions.width} height={article.data.index_image.dimensions.height} />
                            </picture>
                            : article.data.image.url ? 
                            <picture>
                                <source srcSet={`${article.data.image.url}&w=1200&fit=max`} media="(min-width: 1500px)" />
                                <source srcSet={`${article.data.image.url}&w=800&fit=max`} media="(min-width: 1200px)" />
                                <source srcSet={`${article.data.image.url}&w=500&fit=max`} media="(min-width: 768px)" />
                                <source srcSet={`${article.data.image.url}&w=1000&fit=max`} media="(min-width: 500px)" />
                                <img src={`${article.data.image.url}&w=700&fit=max`} alt="" className="c-project-listing__index-item-media" loading="lazy" width={article.data.image.dimensions.width} height={article.data.image.dimensions.height} />
                            </picture>
                            : null}
                            {article.data.index_image2.url ? 
                            <picture>
                                <source srcSet={`${article.data.index_image2.url}&w=1200&fit=max`} media="(min-width: 1500px)" />
                                <source srcSet={`${article.data.index_image2.url}&w=800&fit=max`} media="(min-width: 1200px)" />
                                <source srcSet={`${article.data.index_image2.url}&w=500&fit=max`} media="(min-width: 768px)" />
                                <source srcSet={`${article.data.index_image2.url}&w=1000&fit=max`} media="(min-width: 500px)" />
                                <img src={`${article.data.index_image2.url}&w=700&fit=max`} alt="" className="c-project-listing__index-item-media" loading="lazy" width={article.data.index_image2.dimensions.width} height={article.data.index_image2.dimensions.height} />
                            </picture>
                            : article.data.image2.url ? 
                            <picture>
                                <source srcSet={`${article.data.image2.url}&w=1200&fit=max`} media="(min-width: 1500px)" />
                                <source srcSet={`${article.data.image2.url}&w=800&fit=max`} media="(min-width: 1200px)" />
                                <source srcSet={`${article.data.image2.url}&w=500&fit=max`} media="(min-width: 768px)" />
                                <source srcSet={`${article.data.image2.url}&w=1000&fit=max`} media="(min-width: 500px)" />
                                <img src={`${article.data.image2.url}&w=700&fit=max`} alt="" className="c-project-listing__index-item-media" loading="lazy" width={article.data.image2.dimensions.width} height={article.data.image2.dimensions.height} />
                            </picture>
                            : null}
                        </div>
                    </div>
                    <div className="c-project-listing__index-item-content-container">
                        <h3 className="c-project-listing__index-item-title"><AvoidOrphan text={article.data.summary.text}></AvoidOrphan></h3>
                        <p className="c-project-listing__index-item-summary">{article.data.title.text}</p>
                    </div>
                </Link>
            </div>
        )
    } 
    
    return null
}

export default ProjectListIndexItem